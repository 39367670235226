import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <iframe
        height={"100%"}
        width={"100%"}
        src="https://4gameground.com/nnes/?nsyst2=nes&nsyst=nes&fl=487_Tank_1990&rom=nes"></iframe>

      <div className="socials">
        <a
          href="https://twitter.com/Tank1990PVP"
          target="_blank"
          rel="noopener noreferrer">
          Twitter
        </a>
        <a
          href="https://t.me/Tank1990PVP"
          target="_blank"
          rel="noopener noreferrer">
          Telegram
        </a>
      </div>

      <div className="info">
        <p>
          0xFF128a877f8f5Fa8825EE3fc6dF13cAc8c8831CC <br />
          owner@tank1990pvp.com
        </p>
      </div>
    </div>
  );
}

export default App;
